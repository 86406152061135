import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import { navigate } from 'gatsby'

import RowWrapper from '../../utils/rowWrapper';

import { encode } from '../../utils/util';

import Card from './contact-card';

// import icons for cards
import Message from '../../images/message.png';
import EmailIcon from '../../images/email.png';
import Location from '../../images/location.png';

// import social icons
import Social from '../../components/home/social';


 
const Container = styled.div`
 min-height: 100vh;
 padding-top: 8rem;
 padding-bottom: 8rem;

 @media ${props => props.theme.breaks.down('md')} {
    height: auto;
    padding-top: 0;
  }
`

const Flex = styled.div`
 display: flex;
 justify-content: space-between;
 align-items: flex-start;
 min-height: 60rem;
 height: 75vh;
 max-height: 70rem;

 @media ${props => props.theme.breaks.down('md')} {
  flex-direction: column;
  height: auto;
  min-height: auto;
  max-height: none;
  align-items: center;
 }
`

const HeadingFlex = styled.div`
  max-width: 30rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const Heading = styled.h3`
  color: ${props => props.theme.colors.text};

  @media ${props => props.theme.breaks.down('lg')} {
    font-size: 4rem;
  }

  @media ${props => props.theme.breaks.down('md')} {
    font-size: 2.5rem;
    width: 21rem;
    margin: 4rem auto;
    text-align: center;
  }
`

const FormFlex = styled.form`
  width: 50rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;

  @media ${props => props.theme.breaks.down('lg')} {
    width: 40rem;
  }

  @media ${props => props.theme.breaks.down('md')} {
    width: 25rem;
    min-height: 23rem;
    order: 3;
    margin-bottom: 3rem;
  }
`

const Field = styled.input`
  display: block;
  height: 6.5rem;
  border: 1px solid ${props => props.theme.colors.text};
  border-radius: 3px;
  padding: 1rem;
  margin-bottom: 4rem;

  &::placeholder {
    color: ${props => props.theme.colors.text};
    opacity: .47;
    font-size: 1.6rem;

    @media ${props => props.theme.breaks.down('md')} {
      font-size: 1.2rem;
    }
  }

  @media ${props => props.theme.breaks.down('lg')} {
    height: 4.5rem;
  }

  @media ${props => props.theme.breaks.down('md')} {
    height: 3rem;
    font-size: 1.6rem;
    margin-bottom: 2rem;
  }
`

const TextArea = styled.textarea`
  display: block;
  height: 25rem;
  border: 1px solid ${props => props.theme.colors.text};
  border-radius: 3px;
  padding: 1rem;
  margin-bottom: 4rem;


  &::placeholder {
    color: ${props => props.theme.colors.text};
    opacity: .47;
    font-size: 1.6rem;

    @media ${props => props.theme.breaks.down('md')} {
      font-size: 1.2rem;
    }
  }

  @media ${props => props.theme.breaks.down('lg')} {
    height: 18rem;
  }

  @media ${props => props.theme.breaks.down('md')} {
    height: 8rem;
    font-size: 1.6rem;
    margin-bottom: 2rem;
  }
`

const Button = styled.button`
  display: block;
  height: 6.5rem;
  background-color: ${props => props.theme.colors.text};
  color: ${props => props.theme.colors.body};
  font-size: 1.8rem;
  font-weight: 500;
  text-transform: uppercase;
  text-align: center;
  padding: 2.5rem 0;
  outline: none;
  border: 1px solid ${props => props.theme.colors.text};
  border-radius: 3px;
  transition: all .2s;
  cursor: pointer;

  &:hover {
    box-shadow: .3rem .5rem 1rem rgba(0, 0, 0, .16);
  }

  @media ${props => props.theme.breaks.down('md')} {
    height: 3.3rem;
    font-size: 1.2rem;
    padding: 1rem 0;
  }
`


const InfoFlex = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100%;

  @media ${props => props.theme.breaks.down('md')} {
    order: 2;
    min-height: 19rem;
    margin-bottom: 3rem;
  }
`

const Contacts = styled.a`

 @media ${props => props.theme.breaks.down('md')} {
    font-size: 1.8rem;
  }
`

const Address = styled.span`
  font-size: 1.8rem;

  @media ${props => props.theme.breaks.down('md')} {
    font-size: 1.2rem;
  }
`

const SocialIcon = styled.div`
  border: 1px solid ${props => props.theme.colors.color};
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    height: 2rem;
    object-fit: contain;
  }
`

const SocialContainer = styled.div`
  width: 20rem;

  @media ${props => props.theme.breaks.down('md')} {
    display: none;
  }
`

export default ({ id }) => {

  const cardInfo = [
    {
      id: "message",
      icon: Message,
      text: <Contacts href="tel:201-268-5212">201.268.5212</Contacts>
    },
    {
      id: "email",
      icon: EmailIcon,
      text: <Contacts href="mailto:office@urbo.care">office@urbo.care</Contacts>
    },
    {
      id: "address",
      icon: Location,
      text: <Address>54 Atlantic Street. Jersey City, NJ 07304</Address>
    }
  ]

  const [buttonMsg, setMsg] = useState("Get Care!")

  const [state, setState] = useState({});

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const form = e.target
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...state,
      }),
    })
      .then(() => {
        setState({name: '', email: '', message: ''})
        setMsg("Submitted!")
      })
      .catch((error) => alert(error))
      
  }
 
 return (
 <Container id={id}>

   <RowWrapper>

    <Flex>

      <HeadingFlex >
          <Heading id="contact-heading">Don't let your assets become a liability</Heading>

          <SocialContainer>
            <Social />
          </SocialContainer>
      </HeadingFlex>

      <FormFlex
         name="contact-us"
         method="post"
         action="/submitted"
         data-netlify="true"
         data-netlify-honeypot="bot-field"
         onSubmit={handleSubmit}
      >

      <input type="hidden" name="contact-us" value="contact-us" />
        <p hidden>
          <label>
            Don’t fill this out: <input name="bot-field" onChange={handleChange} />
          </label>
        </p>

        <Field type="text" name="name" placeholder='Name*' value={state.name} onChange={handleChange} required />

        <Field type="email" name="email" placeholder='Email*' value={state.email} onChange={handleChange} required />

        <TextArea name="message" placeholder='Message' value={state.message} onChange={handleChange}></TextArea>

        <Button type="submit">{buttonMsg}</Button>

      </FormFlex>

      <InfoFlex>
          {cardInfo.map(({icon, text, id}) => 
            <Card key={id} icon={icon} text={text} height="19rem" width="32rem"></Card>
          )}
      </InfoFlex>
   
   </Flex>
   
   </RowWrapper>

 </Container>
 )
}