import React from 'react'
import styled, {css} from 'styled-components'

const Orange = styled.div`
  width: 7.5rem;
  background-color: #E99349;
  opacity: .12;
  height: ${props => props.height || '43rem'};
  top: ${props => props.top || 0};
  left: ${props => props.left || 0};
  position: absolute;

  @media ${props => props.theme.breaks.down('md')} {

    ${props => props.rotate ? css`
      transform: rotateZ(${props.rotate});
    ` : ""};

    ${props => props.mDim ? css`
      height: ${props.mDim.height || props.height || '43rem'};
      width: ${props.mDim.width || "7.5rem"};
      top: ${props.mDim.top || props.top || 0};
      left: ${props.mDim.left || props.left || 0};
    ` : ""};

  }
`
 
export default ({height, top, left, mDim, rotate, id}) => {
 
 return (
  <Orange id={id} height={height} top={top} left={left} rotate={rotate} mDim={mDim}>
  
  </Orange>
 )
}