import React, {useEffect, useState} from 'react'
import styled, {css} from 'styled-components'

import RowWrapper from '../../utils/rowWrapper';

import StarImage from '../../images/star.png';

import CarouselCard from './carousel-card';

// import "react-responsive-carousel/lib/styles/carousel.min.css"; 
// import { Carousel } from 'react-responsive-carousel';

import CarouselGsap from '../../utils/carousel';

// import arrows for carousel
import ArrowUp from '../../images/up-arrow.png'
import ArrowDown from '../../images/down-arrow.png'

// import arrows for desktop
import ArrowRight from '../../images/right-arrow.png'
import ArrowLeft from '../../images/left-arrow.png'

import ArrowSvg from '../../images/arrow.js';


const Container = styled.div`
  min-height: 100vh;
  padding-top: 8rem;
  padding-bottom: 2rem;
  background-color: ${props => props.theme.colors.gray};


  @media ${props => props.theme.breaks.down('md')} {
    height: auto;
  }
`

const MainFlex = styled.div`
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 3rem;
  padding-top: 2rem;

  @media ${props => props.theme.breaks.down('md')} {
    padding-top: 0;
    padding-bottom: 0;
    display: block;
    min-height: none;
  }
`

const TopFlex = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  @media ${props => props.theme.breaks.down('md')} {
    flex-direction: column;
  }
`

const HeadingContainer = styled.div`

`

const Heading = styled.h2`
  width: 46rem;
  line-height: 7rem;

  @media ${props => props.theme.breaks.down('md')} {
    text-align: center;
    margin: 0 auto;
    width: auto;
  }
`
const SubHeading = styled.span`
  display: block;
  font-family: "Montserrat", sans-serif;
  font-size: 2.2rem;
  width: 35rem;
  margin-top: 1.5rem;
  line-height: 3rem;

  @media ${props => props.theme.breaks.down('md')} {
    font-size: 1.4rem;
    width: 15rem;
    text-align: center;
    margin: 1.5rem auto;
    line-height: 2rem;
  }
`

const RatingContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 50rem;

  @media ${props => props.theme.breaks.down('lg')} {
    width: 40rem;
  }

  @media ${props => props.theme.breaks.down('md')} {
    display: none;
  }
`

const RatingContainerMobile = styled.div`
  display: flex;
  justify-content: space-between;
  width: 25rem;
  margin: 8rem auto 2rem auto;

  .rating-star-mobile {
    visibility: hidden;
    transform: scale(.2);
  }

  @media ${props => props.theme.breaks.up('md')} {
    display: none;
  }
`

const StarContainer = styled.div`
  width: 8rem;

  img {
    object-fit: contain;
    width: 100%;
  }

  @media ${props => props.theme.breaks.down('lg')} {
    width: 6rem;
  }

  @media ${props => props.theme.breaks.down('md')} {
    width: 4rem;
    /* margin-bottom: 5rem; */
  }
`

const CarouselContainer = styled.div`
  margin: 0 auto;
  margin-top: 15rem;
  width: 1240px;
  max-height: 43rem;

  @media ${props => props.theme.breaks.down('lg')} {
    width: 960px;
    max-height: 32rem;
  }

  @media ${props => props.theme.breaks.down('md')} {
    width: 100%;
    margin-top: 0;
    max-height: auto;
  }
`

const ArrowStyles = styled.div`
  width: 6rem;
  margin: 0 auto;
  height: 3rem; 
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    ${props => props.dir === "up" ? css`
      transform: rotate(-90deg);
    ` : ""}

    ${props => props.dir === "down" ? css`
      transform: rotate(90deg);
    ` : ""}

    /* ${props => props.dir === "right" ? css`
      transform: rotate();
    ` : ""} */

    ${props => props.dir === "left" ? css`
      transform: rotate(180deg);
    ` : ""}

    @media ${props => props.theme.breaks.down('md')} {
      /* width: 2.7rem; */
      height: 2.7rem;
    }
  }
`

const Arrow = ({dir}) => {
  return (
  <ArrowStyles dir={dir}>
    <ArrowSvg />
  </ArrowStyles>
  )
}

const Star = ({className}) => {
  return <StarContainer className={className}><img src={StarImage} alt="Rating Star" /></StarContainer>
}
 
export default ({id}) => {

  const [mobile, setMobile] = useState(false)

  const [laptop, setLaptop] = useState(false)

  const testimonials = [
    {
      text: '“The best part of working with them? Honesty. Honesty is one thing. I wouldn\’t say that the other companies were dishonest but Urbo cares for the building like it\’s their own problem.”',
      author: "Anonymous"
    },
    {
      text: '“We chose Urbo because they came across as more responsible.”',
      author: "T. Fisch"
    },
    {
      text: '“The building tenants were happy that someone was in charge. That they\'re working on it. That we\'re not ignoring them.”',
      author: "Albert V."
    },
    {
      text: '“The best part of working with Urbo? Communication. They get back to me telling me why this is like this, why that is like that.”',
      author: "Joel H."
    },
    {
      text: '“It\'s such a pleasure to work with them.”',
      author: "Anonymous"
    },
  ]

  useEffect(() => {
    if (window.innerWidth < 1280 && window.innerWidth > 960)
      setLaptop(true)


    if (window.innerWidth < 960)
      setMobile(true)
  }, [])

  const arrowImages = ["up", "down"]

  const arrowImagesDesk = ["right", "left"]

  const Arrows = mobile ? arrowImages.map(cur => 
      <Arrow dir={cur}></Arrow>
    ) : arrowImagesDesk.map(cur => 
      <Arrow dir={cur}></Arrow>
    )

  const cards = testimonials.map(({text, author}, i) => 
    <CarouselCard key={`${author}${i}`} text={text} id={`card${i}`} author={author} className="carousel-cards"></CarouselCard>
  )

 
 return (
  <Container id={id}>
    <RowWrapper>

      <MainFlex>
      <TopFlex>

      <HeadingContainer>
        <Heading>
            Testimonials
        </Heading>

        <SubHeading id="testi-sub">
          Don't just take our word for it.
        </SubHeading>
      </HeadingContainer>

      <RatingContainer>
        {[...Array(5)].map((cur, i) => 
          <Star key={`star${i}`} className="rating-star"></Star>
        )}
       
      </RatingContainer>

      </TopFlex>
     
         
    
    {/* <CarouselComp axis={mobile ? "vertical" : "horizontal"} autoPlay infiniteLoop centerMode centerSlidePercentage={33} showThumbs={false} showStatus={false} showIndicators={false}>
      {testimonials.map(({text, author}, i) => 
        <CarouselCard key={`${author}${i}`} text={text} author={author} />
      )}
    </CarouselComp> */}
    
    <CarouselContainer>
      
      {/* {console.log(mobile, 'mobile')} */}
      <CarouselGsap cards={cards} height={mobile ? 16 : ''} width={mobile ? "" : laptop ? 30 : 40} carouselHeight={mobile ? "" : laptop ? "26rem" : "36rem"} margin={2} arrows={Arrows} axis={mobile ? "vertical" : "horizontal"} arrowWidth={mobile ? "" : "3rem"}>

    </CarouselGsap>
    
    </CarouselContainer>

    </MainFlex>

    </RowWrapper>

     <RatingContainerMobile className='mobile-stars'>
     {[...Array(5)].map((cur, i) => 
        <Star key={`star-mobile${i}`} className="rating-star-mobile"></Star>
      )}
    </RatingContainerMobile>


  </Container>
 )
}