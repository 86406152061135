import React from 'react'
import styled from 'styled-components'

import RowWrapper from '../../utils/rowWrapper';

import RelyCard from './card';

import OrangeBar from './orange-bar';

// import icons
import Emergency from '../../images/emergency.png';
import Report from '../../images/reports.png';
import Service from '../../images/service.png';

//import Pipe image
import Pipe from '../../images/pipe.png';

const Container = styled.div`
  min-height: 100vh;
  padding-top: 8rem;
  padding-bottom: 5rem;

  @media ${props => props.theme.breaks.down('md')} {
    min-height: auto;
    padding-bottom: 2rem;
  }
`
const Heading = styled.h2`
  width: 46rem;
  line-height: 7rem;

  @media ${props => props.theme.breaks.down('md')} {
    width: 32rem;
    line-height: 3.2rem;
    text-align: center;
    margin: 0 auto;
  }
`
const SubHeading = styled.span`
  display: block;
  font-family: "Montserrat", sans-serif;
  font-size: 2.2rem;
  width: 30rem;
  margin-top: 1.5rem;
  line-height: 2.8rem;

  @media ${props => props.theme.breaks.down('md')} {
    display: none;
  }
`

const SubHeadingMobile = styled.span`
  display: block;
  font-size: 1.4rem;
  text-transform: uppercase;
  font-weight: 300;
  width: 20rem;
  margin: 4.5rem auto;
  line-height: 2rem;
  color: ${props => props.theme.colors.text};
  text-align: center;

  @media ${props => props.theme.breaks.up('md')} {
    display: none;
  }
`

const ServiceFlex = styled.div`
  display: flex;
  justify-content: space-between;
  /* align-items: flex-end; */
  position: relative;

  @media ${props => props.theme.breaks.down('md')} {
    flex-direction: column;
  }
`

const HeadingFlex = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const TextFlex = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-height: 100%;

  @media ${props => props.theme.breaks.down('md')} {
    min-height: 50rem;
    margin-top: 3rem;
  }
`

const ImageFlex = styled.div`
  padding-top: 0;
  img {
    height: 65rem;
    object-fit: contain;
  }

  @media ${props => props.theme.breaks.down('md')} {
    display: none;
  }
`

const BarContainer = styled.div`
  height: 23rem;
  position: relative;
  margin: 6rem 0;
  margin-bottom: 3rem;
  width: 100%;

  @media ${props => props.theme.breaks.down('md')} {
    display: none;
  }
`


 
export default ({ id }) => {

 
 return (
  <Container id={id}>
    <RowWrapper>

      <ServiceFlex>
        <HeadingFlex>
          <Heading>
              With Urbo, you can always rely on:
          </Heading>
          <BarContainer>
            <OrangeBar height="23rem" left="25%" id="rely-orange" />
          </BarContainer>

          <SubHeading id="rely-sub">
          And we do it all with as much (or as little) involvement as you’d like.
          </SubHeading>
        </HeadingFlex>

        <TextFlex>
         <RelyCard icon={Emergency} id="emergency" text="24/7 Emergency Line for Tenants" />
         <RelyCard icon={Report} id="report" text="Monthly & Annual Reports" />
         <RelyCard icon={Service} id="service" text="Timely, Detailed Service" />
        </TextFlex>

        <SubHeadingMobile className='about-sub-mobile'>
        And we do it all with as much (or as little) involvement as you’d like.
        </SubHeadingMobile>

        <ImageFlex>
          <img src={Pipe} alt="" />
        </ImageFlex>
      </ServiceFlex>
    </RowWrapper>
  </Container>
 )
}