import React from 'react' 

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19.883"
      height="26.432"
      viewBox="0 0 19.883 26.432"
    >
      <g transform="translate(-30.811 -22.73)">
        <path
          fill="#183264"
          d="M43.645 25.739a6.884 6.884 0 11-12.733 2.294l1.855-1.079a.121.121 0 01.165.044l2.6 4.469a.27.27 0 00.371.1l4.722-2.749a.27.27 0 00.1-.371l-2.6-4.473a.118.118 0 01.044-.162l1.859-1.079a6.837 6.837 0 013.617 3.006z"
          data-name="Path 33"
        ></path>
        <path
          fill="#183264"
          d="M65.546 66.363l-3.373 1.977a.5.5 0 01-.7-.162l-7.81-14.012a.506.506 0 01.213-.675l2.79-1.633a.506.506 0 01.675.145l8.4 13.684a.5.5 0 01-.2.675z"
          data-name="Path 34"
          transform="translate(-15.107 -19.256)"
        ></path>
        <circle
          cx="0.759"
          cy="0.759"
          r="0.759"
          fill="#e69559"
          data-name="Ellipse 1"
          transform="translate(46.502 44.604)"
        ></circle>
      </g>
    </svg>
  );
}

export default Icon;