import React from 'react';
import styled from 'styled-components';

import HoverGsap from '../../utils/hover-gsap';
 
const Container = styled.div`
  width: ${props => props.width ? props.width : "32rem"};
  height: ${props => props.height ? props.height : "20rem"};
  background-color: ${props => props.theme.colors.gray};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 3rem 0;
  transition: all .2s;

  &:hover {
    filter: drop-shadow(.8rem .8rem 1.5rem rgba(0, 0, 0, .16))
  }

  @media ${props => props.theme.breaks.down('md')} {
    width: ${props => props.width ? props.width : "25rem"};
    height: ${props => props.height ? props.height : "15rem"};
  }
`

const Circle = styled.div`
  height: 6rem;
  width: 6rem;
  border-radius: 50%;
  background-color: ${props => props.theme.colors.color};
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 2rem;
    /* height: 2.8rem; */
    object-fit: contain;
  }

  @media ${props => props.theme.breaks.down('md')} {
    height: 3.8rem;
    width: 3.8rem;

    img {
      width: 1.4rem;
    }
  }
  
`
const Title = styled.span`
  display: block;
  font-size: 2rem;
  width: 17rem;
  text-align: center;

  @media ${props => props.theme.breaks.down('md')} {
    font-size: 1.5rem;
  }
`


export default ({icon, text, height, width}) => {
 
 return (
    <Container className='rely-cards' height={height} width={width}>
      <Circle><img src={icon} alt="" /></Circle>
      <Title>{text}</Title>
    </Container>
 )
}