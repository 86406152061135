import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="29.863"
      height="31.428"
      viewBox="0 0 29.863 31.428"
    >
      <g data-name="Vacancy filling" transform="translate(-27.139 -31.39)">
        <rect
          width="4.75"
          height="31.428"
          fill="#183264"
          data-name="Rectangle 30"
          rx="0.63"
          transform="translate(39.691 31.39)"
        ></rect>
        <g data-name="Group 10" transform="translate(27.139 31.846)">
          <rect
            width="27.606"
            height="16.819"
            fill="#183264"
            data-name="Rectangle 31"
            rx="1.81"
            transform="rotate(-8.88 27.386 2.134)"
          ></rect>
          <g
            fill="#e69559"
            data-name="Group 9"
            transform="translate(5.727 7.589)"
          >
            <path
              d="M43.128 57.981l-1.058.167-1.022-1.225.207 1.351-.855.135-.54-3.489 1.351-.207a1.031 1.031 0 01.9.239 1.139 1.139 0 01.333.666 1.04 1.04 0 01-.144.779.923.923 0 01-.419.315zm-1.567-2.174a.419.419 0 00-.176-.293.522.522 0 00-.405-.059h-.162l.135.869.162-.027a.522.522 0 00.369-.18.419.419 0 00.077-.324z"
              data-name="Path 42"
              transform="translate(-39.86 -52.245)"
            ></path>
            <path
              d="M49.563 56.857l-1.868.288-.545-3.5 1.873-.293.117.756-1.017.158.095.594.959-.149.117.756-.959.149.1.644 1.017-.158z"
              data-name="Path 43"
              transform="translate(-43.868 -51.509)"
            ></path>
            <path
              d="M56.74 55.473l-.855.135-1.9-1.9.311 2.138-.851.131L52.9 52.5l.855-.135 1.918 1.873-.328-2.138.855-.131z"
              data-name="Path 44"
              transform="translate(-47.029 -50.75)"
            ></path>
            <path
              d="M63.873 51.775l-.707.131.423 2.7-.851.131-.423-2.728-.7.108-.117-.756 2.251-.351z"
              data-name="Path 45"
              transform="translate(-51.758 -50.222)"
            ></path>
            <path
              d="M71.737 53.475l-.9.14-.306-.572-1.18.18-.131.644-.9.14.72-3.683.936-.144zm-1.553-1.089l-.527-1.053-.184 1.167z"
              data-name="Path 46"
              transform="translate(-55.507 -49.766)"
            ></path>
            <path
              d="M78.413 52.587l-1.877.293L76 49.4l.851-.135.45 2.733.995-.149z"
              data-name="Path 47"
              transform="translate(-59.73 -49.26)"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Icon;