import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="37.432"
      height="67.203"
      viewBox="0 0 37.432 67.203"
    >
      <g data-name="Group 75" transform="translate(-957.75 -1013.75)">
        <g data-name="Group 74" transform="translate(959 1015)">
          <g data-name="Group 73">
            <g data-name="Layer 2">
              <g data-name="Layer 1">
                <rect
                  width="34.932"
                  height="64.704"
                  fill="none"
                  stroke="#fff"
                  strokeMiterlimit="10"
                  strokeWidth="2.5"
                  data-name="Rectangle 145"
                  rx="17.466"
                ></rect>
              </g>
            </g>
            <g data-name="Layer 2" transform="translate(17.466 17.664)">
              <g data-name="Layer 1">
                <path
                  id="mouse-scroll"
                  fill="none"
                  stroke="#fff"
                  strokeLinecap="round"
                  strokeMiterlimit="10"
                  strokeWidth="3"
                  d="M0 0L0 10.544"
                  data-name="Line 19"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Icon;