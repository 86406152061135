import React, { useRef, useEffect } from "react"
import {Link, navigate} from 'gatsby';
import Layout from "../components/layout"
import styled, { createGlobalStyle } from 'styled-components';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger'


import Social from '../components/home/social';


import RowWrapper from "../utils/rowWrapper"

// import nav for mobile
import Nav from '../components/nav/nav';

import Contact from '../components/contact-button';

// import Footer Logo
import LogoFooter from '../images/logo-footer.png';

// cover image for hero section
import Cover from '../images/cover.jpg';
// mobile cover image
import MobileCover from '../images/mobile-cover.jpg';

// import scroll mouse icon
import Mouse from '../images/mouse';

// image for Own section
import Keys from '../images/keys.png';

import OrangeBar from '../components/home/orange-bar';

// services section
import Services from '../components/home/services'

// rely section
import Rely from '../components/home/rely';

// testimonials section
import Testimonials from '../components/home/testimonials';

// contact us section
import ContactUs from '../components/home/contact-us';

gsap.registerPlugin(ScrollTrigger);



const Hero = styled.div`
  height: auto;
  position: relative;
  min-height: 100vh;
  overflow-y: hidden;

  img {
    object-position: center top;
    height: auto;

    @media ${props => props.theme.breaks.down('md')} {
      width: 100%;
      height: 100%;
      object-position: center 60%;
    }
  }

  .desktop-cover {
    opacity: 0;
    @media ${props => props.theme.breaks.down('md')} {
      display: none;
    }
  }


  .mobile-cover {
    @media ${props => props.theme.breaks.up('md')} {
      display: none;
    }
  }

  @media ${props => props.theme.breaks.down('md')} {
    height: 100vh;
  }
`



const Title = styled.h2`
  width: 100%;
  color: ${props => props.theme.colors.body};
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;

  @media ${props => props.theme.breaks.down('md')} {
    bottom: 10rem;
    top: auto;
    width: 25rem;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: 400;
  }

`

const ImgCover = styled.div`
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 26rem;
  overflow: hidden;

  @media ${props => props.theme.breaks.down('lg')} {
    height: 15rem;
  }

  @media ${props => props.theme.breaks.down('md')} {
    display: none;
  }
`

const MouseContainer = styled.div`
  width: 3.5rem;
  height: 6.2rem;
  position: absolute;
  top: 80vh;
  left: 50%;
  transform: translateX(-50%);

  svg {
    width: 100%;
  }

  @media ${props => props.theme.breaks.down('lg')} {
    top: 65vh;
    width: 3rem;
    height: 5.8rem;
  }

  @media ${props => props.theme.breaks.down('md')} {
    display: none;
  }
`

const WhiteFlex = styled.div`
  background-color: ${props => props.theme.colors.body};
  height: 100%;
  width: 50%;
`

const AnimateFlex = styled.div`
  height: 100%;
  background-color: ${props => props.theme.colors.body};
  width: 50%;
  transform-origin: left bottom;
  visibility: hidden;
`

const Own = styled.div`
  min-height: 100vh;
  margin-top: -15rem;
  position: relative;
  padding-bottom: 4rem;
  z-index: 1;

  @media ${props => props.theme.breaks.down('lg')} {
    margin-top: -10rem;
  }

  @media ${props => props.theme.breaks.down('md')} {
    margin-top: 0;
    height: auto;
  }
`

const OwnTitle = styled.h2`
  width: 50%;
  /* padding-left: 10rem; */

  @media ${props => props.theme.breaks.down('md')} {
    margin: 0 auto;
    margin-top: 8rem;
    text-align: center;
  }

`

const OwnContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5rem;

  @media ${props => props.theme.breaks.down('md')} {
    flex-direction: column;
    margin-top: 2rem;
  }
`;

const OwnImage = styled.div`
  position: relative;
  img {
    max-width: 55rem;
    position: relative;
    z-index: 5;

  @media ${props => props.theme.breaks.down('md')} {
    max-width: 25rem;
    margin-bottom: 3rem;
  }
  }
`;

const OwnText = styled.div`
  width: 50%;

  h6 {
    margin-top: 2.5rem;
    margin-bottom: .6rem;

    &.small-h6 {
    margin-bottom: 2rem;
  }
  }

  p {
    line-height: 3rem;
    width: 40rem;

    &.smaller {
      font-size: 1.8rem;
      line-height: 2.5rem;
    }
  }

  

  @media ${props => props.theme.breaks.down('md')} {
    width: 90%;

    h6 { 
      text-align: center;

      &.small-h6 {
        font-size: 1.2rem;
        transform: translateY(-15rem);
        position: relative;
        z-index: -1;
        visibility: hidden;
      }

      &.large-h6 {
        width: 15rem;
        margin: 1rem auto 0 auto;
        line-height: 2.2rem;
        visibility: hidden;
      }
    }

    p {
      text-align: center;
      font-size: 1.5rem;
      width: 24rem;
      line-height: 2.2rem;
      margin: 0 auto;
      visibility: hidden;

      &.smaller {
        font-size: 1.2rem;
        line-height: 2rem;
        visibility: visible;
        transform: translateY(-15rem);
        position: relative;
        z-index: -1;
        visibility: hidden;
      }
    }
  }
`;

const OwnMobileDivider = styled.div`
  background-color: ${props => props.theme.colors.orangeDark};
  width: 12rem;
  height: 1px;
  margin: 1rem auto;
  visibility: hidden;

  @media ${props => props.theme.breaks.up('md')} {
    display: none;
  }
`

const MobileGray = styled.div`
  background-color: ${props => props.theme.colors.gray};
  height: 19rem;
  padding: 1rem 2rem;

  @media ${props => props.theme.breaks.up('md')} {
    background-color: transparent;
    padding: 0;
    height: auto;
  }
`

const Divider = styled.div`
  background-color: ${props => props.theme.colors.orangeDark};
  height: ${props => props.height || '4rem'};

  @media ${props => props.theme.breaks.up('md')} {
    display: none;
  }

`

const Footer = styled.div`
  height: 16rem;
  background-color: ${props => props.theme.colors.text};
  position: relative;

  span {
    display: block;
    color: ${props => props.theme.colors.body};
    font-family: "Montserrat", sans-serif;
    font-size: 1.5rem;
    /* text-align: center; */

  @media ${props => props.theme.breaks.down('md')} {
    font-size: 1rem;
  }

  }

  @media ${props => props.theme.breaks.down('md')} {
    display: block;
    height: 9rem;
    padding-top: 1.5rem;

    .copyright {
      font-size: .8rem;
    }
  }
`

const FooterFlex = styled.div`
  height: 16rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media ${props => props.theme.breaks.down('md')} {
    height: 6rem;
    flex-direction: column;
    width: 80%;
    margin: 0 auto;
    align-items: flex-start;
  }
`

const FooterLogo = styled.div`
  /* position: absolute; */
  /* padding-left: 5rem; */
  display: flex;
  justify-content: space-between;

  .footer-logo {
    width: 14rem;
    object-fit: contain;
  }

  @media ${props => props.theme.breaks.down('md')} {
    position: relative;
    margin-bottom: 1rem;
    width: 100%;

    .footer-logo {
      height: 3rem;
      width: auto;
    }
  }
`

const SocialContainer = styled.div`
  width: 8rem;

  @media ${props => props.theme.breaks.up('md')} {
    display: none;
  }
`

const Attribution = styled.a`
  font: inherit;
  color: inherit;
`


// markup
const IndexPage = () => {

  const animate = useRef()

  useEffect(() => {

    const mobileJs = window.innerWidth < 960;

    // const tlAnimate = gsap.timeline({
    //   scrollTrigger: {
    //     trigger: animate.current,
    //     start: "top 500",
    //     toggleActions: "play none none none"
    //   }
    // })

    // tlAnimate.to(animate.current, { scale: 1.03, filter: "drop-shadow(3px 5px 20px rgba(0, 0, 0, .1))", duration: .3})
    // .to(animate.current, { rotateZ: "75deg", duration: 1.5})
    // .to(animate.current, { y: 250, duration: .5}, "-=1.2")
    // .to(animate.current, { autoAlpha: 0, duration: .5}, "-=1")

    const tl1 = gsap.timeline();

    tl1.to("#mouse-scroll", {y: 10, duration: .8, repeat: -1, yoyo: true})

    const tl2 = gsap.timeline() 

    tl2.fromTo(".desktop-cover", {y: 350, x: -150, autoAlpha: 0}, { y: 0, x: 0, autoAlpha: 1, duration: 3, ease: "slow(0.5, 0.1, false)"})

    const tl3 = gsap.timeline({
      
      scrollTrigger: {
        trigger: "#section-own",
        start: mobileJs ? "top 20%" : "top 40%",
        toggleActions: "play none none none"
      }
    })


    const tl4 = gsap.timeline({
      scrollTrigger: {
        trigger: "#services",
        start: mobileJs ? "top 20%" : "center 30%",
        toggleActions: "play none none none"
      }
    })

    tl4.addLabel("start-tl4")

    const tl5 = gsap.timeline({
      scrollTrigger: {
        trigger: "#about-us",
        start: mobileJs ? "top 20%" : "top top",
        toggleActions: "play none none none"
      }
    })

    const tl6 = gsap.timeline({
      scrollTrigger: {
        trigger: "#section-testimonials",
        start: "top 50%",
        toggleActions: "play none none none"
      }
    })

    const tl7 = gsap.timeline({
      scrollTrigger: {
        trigger: "#contact-us",
        start: "top 30%",
        toggleActions: "play none none none"
      }
    })

    const tl62 = gsap.timeline({
      scrollTrigger: {
        trigger: "#section-testimonials",
        start: "bottom 85%",
        toggleActions: "play none none none"
      }
    })


    if (window.innerWidth > 960) {
      console.log("large screeen");
      tl3.fromTo("#own-orange", {height: 0}, {height: "43rem", duration: 1})
      .fromTo(".own-text", {autoAlpha: 0, y: 100}, {autoAlpha: 1, y: 0, duration: .6}, "-=.5")

      tl5.fromTo("#rely-orange", {height: 0}, {height: "23rem", duration: 1})
      .fromTo("#rely-sub", { autoAlpha: 0, y: 50}, {autoAlpha: 1, y: 0, duration: .5}, "-=.7")

      tl6.addLabel("start-testi").fromTo(".rating-star", {scale: .3, autoAlpha: 0}, {scale: 1, autoAlpha: 1, duration: 2, ease: 'elastic.out', stagger: .2})
      .fromTo("#testi-sub", { autoAlpha: 0, y: 50}, {autoAlpha: 1, y: 0, duration: .5}, "start-testi+=.3")

      tl7.fromTo("#contact-heading", { autoAlpha: 0, y: 100}, {autoAlpha: 1, y: 0, duration: 1})
    } else {

      console.log("small screeen");
      tl3
      .fromTo(".gray-own", {autoAlpha: 0, scale: .5}, {autoAlpha: 1, scale: 1, duration: .6})
      .fromTo([".gray-own h6", ".gray-own p", ".own-mobile-divider"], {scale: 1.1}, {autoAlpha: 1, scale: 1, duration: .5})
      .to([".small-h6", ".smaller"], {autoAlpha: 1, y: 0, duration: .8})

      tl4.to(".tiles", {autoAlpha: 1, scale: 1, duration: .5, stagger: {  
        each: 0.1,
        grid: [2, 4],
        ease: "back.out"
      }}, "start-tl4")

      tl5.fromTo(".rely-cards", {autoAlpha: 0, scale: .6}, {scale: 1, autoAlpha: 1, duration: .6, stagger: .2})
      .fromTo(".about-sub-mobile", {autoAlpha: 0, scale: .6}, {scale: 1, autoAlpha: 1, duration: .5}, "-=.5")

      tl6
      .fromTo("#testi-sub", { autoAlpha: 0, y: 50}, {autoAlpha: 1, y: 0, duration: .5})


      tl62.to(".rating-star-mobile", {scale: 1, autoAlpha: 1, duration: .5, 
        stagger:  {
          each: 0.1,
          from: "start",
          ease: "elastic.out",
        }
      //  onComplete: () => alert("now")
      })
    }
  }, [])


  return (
    <Layout pageTitle="Urbo">
      <Hero>
        <img className="desktop-cover" src={Cover} alt="" />
        <img className="mobile-cover" src={MobileCover} alt="" />
        <Title>Own Ownership</Title>

        <MouseContainer>
          <Mouse></Mouse>
        </MouseContainer>

        <ImgCover>
          <WhiteFlex>&nbsp;</WhiteFlex>
          <AnimateFlex ref={animate} />
        </ImgCover>
      </Hero>

      <Divider height="2rem" />

      <Own id="section-own">
        <RowWrapper>
         <OwnTitle>The easy <br /> way to own <br /> property</OwnTitle>
         <OwnContent>
           <OwnImage>
            <img src={Keys} alt="" />
            <OrangeBar id="own-orange" top="15%" left="32%" rotate="90deg"
              mDim={{mDim: true, width: "4.5rem", height: "30rem", top: "0", left: "40%"}}
            />
           </OwnImage>
           <OwnText className="own-text">
             <MobileGray className="gray-own">
              <h6 className="large-h6">Find property care with Urbo</h6>
                <OwnMobileDivider className="own-mobile-divider" />
                <p>We help building owners manage their property properly, taking care of everything (and we really mean everything).</p>
              </MobileGray>

             <h6 className="small-h6">Servicing the North Jersey area.</h6>
             <p className="smaller">We do all the big and little things that make the most out of your property value.</p>
           </OwnText>
         </OwnContent>
        </RowWrapper>
      </Own>

      <Services id="services" />

      {/* <Divider /> */}

      <Rely id="about-us" />

      <Testimonials id="section-testimonials" />

      <ContactUs id="contact-us" />

      <Footer>

        <RowWrapper>

          <FooterFlex>
            <FooterLogo>
              <img className="footer-logo" src={LogoFooter} alt="" />

              <SocialContainer><Social /></SocialContainer>
            </FooterLogo>

            <span className="copyright">2022 Urbo Property Care. All rights reserved. Designed by <Attribution href="https://celebrand.com">CELEBRAND</Attribution>.</span>
          
          </FooterFlex>
        
        </RowWrapper>
      </Footer>

    </Layout>
  )
}

export default IndexPage
