import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22.875"
      height="22.8"
      viewBox="0 0 22.875 22.8"
    >
      <g
        fill="#183264"
        data-name="Billing and paymrnts"
        transform="translate(-31.45 -31.41)"
      >
        <path
          d="M62.25 31.41h9.762a.9.9 0 01.9.9v9.758H62.25z"
          data-name="Path 26"
          transform="translate(-18.587)"
        ></path>
        <path
          d="M62.25 62h10.662v9.77a.9.9 0 01-.9.9H62.25z"
          data-name="Path 27"
          transform="translate(-18.587 -18.46)"
        ></path>
        <path
          d="M0 0H10.658V10.658H0z"
          data-name="Rectangle 23"
          transform="translate(31.45 43.548)"
        ></path>
        <g data-name="Group 6" transform="translate(33.556 33.512)">
          <rect
            width="1.11"
            height="6.451"
            data-name="Rectangle 24"
            rx="0.04"
            transform="translate(2.669)"
          ></rect>
          <rect
            width="6.451"
            height="1.11"
            data-name="Rectangle 25"
            rx="0.04"
            transform="translate(0 2.673)"
          ></rect>
        </g>
      </g>
    </svg>
  );
}

export default Icon;