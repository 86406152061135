import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22.562"
      height="29.169"
      viewBox="0 0 22.562 29.169"
    >
      <g
        fill="#183264"
        data-name="Legal stuff"
        transform="translate(-29.945 -25.142)"
      >
        <path
          d="M67.377 36.268l1.817-1.537a.339.339 0 00.082-.428l-5.2-8.994a.339.339 0 00-.413-.144l-2.238.806z"
          data-name="Path 30"
          transform="translate(-19.232)"
        ></path>
        <path
          d="M37.831 52.825l-2.238.817a.339.339 0 01-.413-.144l-5.2-8.994a.342.342 0 01.086-.428l1.814-1.557z"
          data-name="Path 31"
          transform="translate(0 -10.615)"
        ></path>
        <path
          d="M68.811 72.771l-.331.191a1.751 1.751 0 01-2.417-.681L58.07 57.57l2.611-1.51 8.749 14.279a1.755 1.755 0 01-.619 2.432z"
          data-name="Path 32"
          transform="translate(-17.18 -18.885)"
        ></path>
        <path
          d="M0 0H10.325V9.177H0z"
          data-name="Rectangle 26"
          transform="rotate(-30.01 77.677 -45.675)"
        ></path>
      </g>
    </svg>
  );
}

export default Icon;