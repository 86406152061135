import React from 'react';
import styled from 'styled-components';

import HoverGsap from '../../utils/hover-gsap';
 
const Container = styled.div`
  width: 32rem;
  height: 19rem;
  background-color: ${props => props.theme.colors.gray};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 3rem 0;
  transition: all .2s;
  position: relative;

  &:hover {
    filter: drop-shadow(.8rem .8rem 1.5rem rgba(0, 0, 0, .16))
  }

  @media ${props => props.theme.breaks.down('lg')} {
    width: 28rem;
  }

  @media ${props => props.theme.breaks.down('md')} {
    width: 25rem;
    height: 5rem;
    padding: 1.5rem 0;
  }
`

const Circle = styled.div`
  height: 6rem;
  width: 6rem;
  border-radius: 50%;
  background-color: ${props => props.theme.colors.color};
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 2rem;
    /* height: 2.8rem; */
    object-fit: contain;
  }

  @media ${props => props.theme.breaks.down('md')} {
    display: none;
  }
  
`
const Title = styled.span`
  display: block;
  font-size: 2rem;
  width: 17rem;
  text-align: center;

  @media ${props => props.theme.breaks.down('md')} {
    font-size: 1.5rem;
  }
`

const IconCircle = styled.div`
  position: absolute;
  left: -1.5rem;
  top: 50%;
  transform: translateY(-50%);
  height: 3rem;
  width: 3rem;
  border-radius: 50%;
  background-color: ${props => props.theme.colors.body};
  display: flex;
  justify-content: center;
  align-items: center;

  .inner {
    height: 2.5rem;
    width: 2.5rem;
    border-radius: 50%;
    background-color: ${props => props.theme.colors.color};
    display: flex;
    justify-content: center;
    align-items: center;
  }

  img {
    width: 1rem;
    /* height: 2.8rem; */
    object-fit: contain;
  }

  @media ${props => props.theme.breaks.up('md')} {
    display: none;
  }

`


export default ({icon, text, height, width}) => {
 
 return (
    <Container height={height} width={width}>
      <Circle><img src={icon} alt="" /></Circle>
      <IconCircle><span className="inner"><img src={icon} alt="" /></span></IconCircle>
      <Title>{text}</Title>
    </Container>
 )
}