import React from 'react'
import styled from 'styled-components'

import { Link } from 'gatsby'


// import social icons
import Google from '../../images/google.png';
import Linked from '../../images/linked.png';
import Whats from '../../images/whats.png';
import Insta from '../../images/insta.png';


const SocialIcon = styled.a`
  border: 1px solid ${props => props.theme.colors.color};
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  img {
    height: 2rem;
    object-fit: contain;
  }

  @media ${props => props.theme.breaks.down('md')} {
    width: 1.5rem;
    height: 1.5rem;

    img {
      width: .8rem;
      height: .8rem;
    }
  }
`

const SocialContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  @media ${props => props.theme.breaks.down('md')} {
  }
`
 
export default () => {
 
 return (
  <SocialContainer>
    <SocialIcon href="https://www.instagram.com/urbopropertymanagement/">
      <img src={Insta} alt="instagram logo" />
    </SocialIcon>
    <SocialIcon href="https://bit.ly/3EGtRoB">
      <img src={Whats} alt="whatsapp logo" />
    </SocialIcon>
    <SocialIcon href="https://www.linkedin.com/in/ben-honig-1264a033/">
      <img src={Linked} alt="linked-in logo" />
    </SocialIcon>
    <SocialIcon href="/">
      <img src={Google} alt="google logo" />
    </SocialIcon>
  </SocialContainer>
 )
}


