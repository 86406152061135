import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';

import gsap from 'gsap';
 
const Container = styled.div`
 position: absolute;
 top: 4rem;
 width: 20rem;
 height: 36rem;
 background-color: ${props => props.theme.colors.gray};
 z-index: 10;
 display: flex;
 flex-direction: column;
 justify-content: center;
 /* align-items: center; */
 padding: 1.5rem 3.5rem;
 box-shadow: 0 3px 6px rgba(0, 0, 0, .16);
 border-radius: 3px;
 border: 1px solid ${props => props.theme.colors.text};
 visibility: hidden;
 transform: scale(0);
`

const Title = styled.span`
  display: block;
  font-size: 1.6rem;
  font-weight: 500;
  margin-bottom: 0;
  text-align: left;
  margin-bottom: 1.5rem;
`

const Text = styled.div`
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 300;
  text-align: left;
`

const X = styled.div`
  position: absolute;
  width: 2rem;
  height: 2rem;
  right: 1rem;
  top: 1rem;
  cursor: pointer;
`

const Line = styled.div`
  position: absolute;
  height: 1.5px;
  background-color: ${props => props.theme.colors.color};
  width: 95%;
  top: 50%;
  transform-origin: center;
  

  &.diagon1 {
    transform: rotate(135deg);
  }

  &.diagon2 {
    transform: rotate(-135deg);
  }
`

export default ({open, handleOpen, info}) => {

  useEffect(() => {
    if (open) {
      gsap.to(".popup", {scale: 1, autoAlpha: 1, duration: .3})
    } else {
      gsap.to(".popup", {scale: 0, autoAlpha: 0, duration: .3})
    }
  }, [open])
 
 return (
 <Container className='popup'>
   <X 
  //  onClick={handleOpen}
   >
     <Line className='diagon1' />
     <Line className='diagon2' />
   </X>
  <Title>{info.title || 'h'}</Title>

  <Text>{info.text || 'h'}</Text>
 </Container>
 )
}