import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="27"
      height="66"
      viewBox="0 0 27 66"
    >
      <path
        fill="#183264"
        d="M33 0l33 27H0z"
        data-name="Polygon 3"
        opacity="0.61"
        transform="rotate(90 13.5 13.5)"
      ></path>
    </svg>
  );
}

export default Icon;