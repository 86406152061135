import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23.788"
      height="23.458"
      viewBox="0 0 23.788 23.458"
    >
      <g
        fill="#183264"
        data-name="Artboard 3 copy 4"
        transform="translate(-28.748 -29.7)"
      >
        <path
          d="M52.395 45.932H28.881c-.113 0-.173-.185-.1-.309 0 0 .931-1.6 1.161-1.986a.407.407 0 00.058-.189c.241-6.155 4.9-11.058 10.64-11.058s10.4 4.9 10.64 11.054a.377.377 0 00.057.188c.23.377 1.165 1.99 1.165 1.99.085.125.006.31-.107.31z"
          data-name="Path 22"
          transform="translate(0 -1.676)"
        ></path>
        <path
          d="M57.723 33.989h-4.24a.132.132 0 01-.132-.113l-.584-4.021a.132.132 0 01.133-.155h5.408a.136.136 0 01.132.155l-.584 4.021a.136.136 0 01-.132.113z"
          data-name="Path 23"
          transform="translate(-14.965)"
        ></path>
        <g data-name="Group 5" transform="translate(34.021 48.732)">
          <path
            d="M60.29 81.212c0-1.4 2.567-1.214 3.957-.279 2.122 1.428 3.117 3.675 2.461 3.69s-2.57-1.851-3.769-1.884c-1.454-.072-2.649-.166-2.649-1.527z"
            data-name="Path 24"
            transform="translate(-53.676 -80.198)"
          ></path>
          <path
            d="M49.355 81.212c0-1.4-2.563-1.214-3.954-.279-2.122 1.428-3.117 3.675-2.461 3.69s2.57-1.851 3.769-1.884c1.455-.072 2.646-.166 2.646-1.527z"
            data-name="Path 25"
            transform="translate(-42.74 -80.198)"
          ></path>
        </g>
      </g>
    </svg>
  );
}

export default Icon;