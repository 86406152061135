import React, {useEffect, useState, useRef} from 'react'
import styled from 'styled-components';

import gsap from 'gsap';

const Container = styled.div`
  width: 34rem;
  height: 15rem;
  background-color: ${props => props.theme.colors.body};
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin: 1.5rem;
  padding: 1rem 3rem;

  &:hover .tile-title {
    opacity: 0;
    transform: scale(0);
  }

  &:hover .tile-sub {
      opacity: 1;
      transform: scale(1);
    }

  @media ${props => props.theme.breaks.down('lg')} {
    width: 28rem;
  }

  @media ${props => props.theme.breaks.down('md')} {
    width: 12.5rem;
    height: 7.5rem;
    padding: 1rem 2rem;
    visibility: hidden;
    transform: scale(.2);
    margin-bottom: 3rem;


    &:hover, &:active {
      box-shadow: 4px 4px 5px rgba(0, 0, 0, .16);
    }
  }

  @media ${props => props.theme.breaks.down('xs')} {
    width: 12rem;
    margin: 1.5rem 1rem;
  }
`

const Title = styled.span`
  display: block;
  font-size: 2.2rem;
  font-weight: 600;
  text-align: center;
  transition: all .4s;

  &.tile-sub {
    position: absolute;
    width: 100%;
    opacity: 0;
    transform: scale(0);
    padding: 1rem 4rem;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media ${props => props.theme.breaks.down('md')} {
      padding: 1rem 1.5rem;
    }
  }

  @media ${props => props.theme.breaks.down('md')} {
    font-size: 1.2rem;
    font-weight: 600;
  }
`

const AltTitle = styled.span`
  font-size: 2rem;
  font-weight: 500;
  text-align: left;
  display: block;
  margin-bottom: 2rem;

  @media ${props => props.theme.breaks.down('md')} {
    font-size: 1.2rem;
    font-weight: 400;
    margin-bottom: 0;
    text-align: center;
  }
`

const Text = styled.div`
  font-size: 1.5rem;
  font-weight: 300;
  text-align: left;

  @media ${props => props.theme.breaks.down('md')} {
    display: none;
  }
`

const IconCircle = styled.div`
  position: absolute;
  left: -4rem;
  top: 50%;
  transform: translateY(-50%);
  height: 6.5rem;
  width: 6.5rem;
  border-radius: 50%;
  background-color: ${props => props.theme.colors.gray};
  display: flex;
  justify-content: center;
  align-items: center;

  .inner {
    height: 5.5rem;
    width: 5.5rem;
    border-radius: 50%;
    background-color: ${props => props.theme.colors.color};
    display: flex;
    justify-content: center;
    align-items: center;
  }

  img {
    width: 2rem;
    /* height: 2.8rem; */
    object-fit: contain;
  }

  svg {
    width: 2rem;
  }

  @media ${props => props.theme.breaks.down('md')} {
    height: 3.8rem;
    width: 3.8rem;
    left: 50%;
    top: -2rem;
    transform: translate(-50%, 0);

    .inner {
      height: 2.8rem;
      width: 2.8rem;
    }

    img {
      width: .8rem;
    }

    svg {
      width: .8rem;
    }
  }

`
 
export default ({title, altTitle, text, icon, pop, id, passRef}) => {

  

  const cir = useRef()
  const tileTitle = useRef()
  const tileSub = useRef()

  let animation = false;

  const tl = gsap.timeline({
      
  })

  const hoverHandler = (hover) => {

    const mobile = window.innerWidth < 960;

    if (!mobile) {
      if (hover) {
        animation = true;
        tl.to(cir.current, {left: "-5rem", duration: .3})
      } else {
        tl.to(cir.current, {left: "-4rem", duration: .3})
      }
    } else {
      if (hover) {
        tl.to(cir.current, {top: "-3rem", duration: .3})
      } else {
        tl.to(cir.current, {top: "-2rem", duration: .3})
      }
    }
  }

 
 return (
    <Container onClick={(e) => pop(e, id)}  onMouseEnter={() => hoverHandler(true)} onMouseLeave={() => hoverHandler(false)} className="tiles">
      <IconCircle ref={cir}><span className="inner">{icon}</span></IconCircle>
      <Title className="tile-title" ref={tileTitle}>{title}</Title>
      <Title className="tile-sub" ref={tileSub}>
        <AltTitle>{altTitle}</AltTitle>
        <Text>{text}</Text>
        </Title>
    </Container>
 )
}