import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24.913"
      height="23.201"
      viewBox="0 0 24.913 23.201"
    >
      <g data-name="Tenan followup" transform="translate(-25.078 -27.355)">
        <path
          fill="#183264"
          d="M34 53.4h18.6v13.922a.067.067 0 01-.067.067H34.071a.067.067 0 01-.071-.067V53.4z"
          data-name="Path 39"
          transform="translate(-5.766 -16.833)"
        ></path>
        <path
          fill="#183264"
          d="M37.43 27.4l-12.3 11.053a.145.145 0 00.1.255h24.612a.145.145 0 00.1-.255L37.632 27.4a.145.145 0 00-.202 0z"
          data-name="Path 40"
        ></path>
        <g
          fill="#e28045"
          data-name="Group 8"
          transform="translate(33.713 39.089)"
        >
          <g data-name="Group 7">
            <circle
              cx="2.384"
              cy="2.384"
              r="2.384"
              data-name="Ellipse 2"
            ></circle>
            <circle
              cx="2.384"
              cy="2.384"
              r="2.384"
              data-name="Ellipse 3"
              transform="translate(2.876)"
            ></circle>
          </g>
          <path
            d="M57.145 68c0 1.769-2.83 3.71-3.608 4.22a.354.354 0 01-.4 0c-.789-.527-3.608-2.476-3.608-4.22 3.351 0 5.137.113 7.616 0z"
            data-name="Path 41"
            transform="translate(-49.516 -65.358)"
          ></path>
        </g>
      </g>
    </svg>
  );
}

export default Icon;