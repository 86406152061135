import React, { useState, useEffect, useCallback } from 'react';
import styled, {css} from 'styled-components';

import gsap from 'gsap';

const OuterContainer = styled.div`
  position: relative;
  height: ${props => props.carouselHeight ? props.carouselHeight : "52rem"};
  width: 100%;
  margin-top: 5rem;
  margin-bottom: 10rem;
  /* @media ${props => props.theme.breaks.up('md')} {
    display: none;
  } */
`
 
const Container = styled.div`
 height: 100%;
 width: 100%;
 position: relative;
 overflow-y: hidden;
 overflow-x: hidden;
`

const InnerContainer = styled.div`
  display: flex;

${props => props.axis === "horizontal" ? css`
  min-width: ${props => props.width}rem;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
` : css`
  min-height: ${props => props.height}rem;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`}
  
`

const Arrow1 = styled.div`
  position: absolute;
  top: ${props => props.axis === "vertical" ? "-2rem" : "50%"};
  right: ${props => props.axis === "horizontal" ? "-4rem" : "auto"};
  width: ${props => props.arrowWidth ? props.arrowWidth : "100%"};
  cursor: pointer;
  transform: translateY(-50%);
`

const Arrow2 = styled.div`
  position: absolute;
  bottom: ${props => props.axis === "vertical" ? "-5rem" : ""};
  top: ${props => props.axis === "horizontal" ? "50%" : "auto"};
  left: ${props => props.axis === "horizontal" ? "-6.5rem" : ""};
  width: ${props => props.arrowWidth ? props.arrowWidth : "100%"};
  cursor: pointer;
  transform: translateY(-50%);
`

let timeout
export default ({cards, axis, height, width, arrows, arrowWidth, margin, visible = 3, carouselHeight}) => {
  // console.log(height, 'height')

  // console.log(axis, "what???");

  const [index, setIndex] = useState(0);

  let indexJs = 0


  const [cardState, setCardState] = useState([]);

  const tl2 = gsap.timeline({
    // onComplete: () => {
    //   setCardState(x => [...x.slice(1), x[0]])
    // }
  })

  const handleArrow = useCallback((dir) => {
    // console.log(index,'index')

    // console.log(dir, indexJs);
    const totalHeight = height + margin;
    const totalWidth = width + margin

    axis = window.innerWidth < 960 ? "vertical" : "horizontal";

    // console.log(axis, "unh unh");

    // console.log(totalHeight);

    let amount = axis === "horizontal" ? totalWidth : totalHeight;

    let zero = axis === "horizontal" ? {x: `0`} : {y: `0`};

    let add = axis === "horizontal" ? {x: `-${amount * (index + 1)}rem`} : {y: `-${amount * (index + 1)}rem`};

    let sub = axis === "horizontal" ? {x: `-${amount * (index - 1)}rem`} : {y: `-${amount * (index - 1)}rem`};

    // console.log(add, sub, 'check ayo');
    
    if (dir === "add") {
      if ((index + visible) === cards.length) {
        tl2.to(".carousel-cards", {...zero, duration: 1})
        setIndex(0)
        indexJs = 0
        return
      }

      // console.log(add);
      tl2.to(".carousel-cards", {...add, duration: 1})
      setIndex(x => x + 1)
      indexJs++
    } else {
        if (index === 0) 
          return

      tl2.to(".carousel-cards", {...sub, duration: 1})
      setIndex(x => x - 1)
      indexJs--
    }
  }, [index, axis, height])

  useEffect(() => {
    setCardState(cards)
  }, [])

  useEffect(() => {
    clearTimeout(timeout)
    timeout = setTimeout(() => {
      handleArrow("add")
    }, 8000)
  }, [index, height])

  
  const cardsHeight = cards.length * (height) + (cards.length - 1) * margin

  const cardsWidth = cards.length * (width) + (cards.length - 1) * margin

 return (
 <OuterContainer carouselHeight={carouselHeight}>
    <Arrow1 arrowWidth={arrowWidth} axis={axis} onClick={() => handleArrow("add")}>{arrows[0]}</Arrow1>

  <Container>
    <InnerContainer axis={axis} height={axis === "vertical" ? cardsHeight : ""} width={axis === "horizontal" ? cardsWidth : ""} className='inner-carousel'>
        {cardState && cardState.map((card) => {
          return card;
        })}
    </InnerContainer>
  </Container>

  <Arrow2 axis={axis} arrowWidth={arrowWidth} onClick={() => handleArrow("sub")}>{arrows[1]}</Arrow2>
 </OuterContainer>
 )
}