import React from 'react';
import styled from 'styled-components';
 
const Container = styled.div`
  width: 40rem;
  height: 35rem;
  background-color: ${props => props.theme.colors.body};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 6rem 5rem;

  @media ${props => props.theme.breaks.down('lg')} {
    width: 30rem;
    height: 25rem;
    padding: 2rem 3rem;
  }

  @media ${props => props.theme.breaks.down('md')} {
    height: 16rem;
    width: 26rem;
    padding: 1rem 3rem;
  }
`

const Text = styled.p`
  font-size: 1.8rem;
  line-height: 2.6rem;
  color: ${props => props.theme.colors.text};
  text-align: center;
  letter-spacing: .2px;
  margin-bottom: 1rem;

  @media ${props => props.theme.breaks.down('lg')} {
    font-size: 1.6rem;
    line-height: 2.1rem;
  }

  @media ${props => props.theme.breaks.down('md')} {
    font-size: 1.2rem;
    line-height: 2rem;
  }
`

const Author = styled.span`
  display: block;
  text-align: right;
  font-size: 1.8rem;
  color: ${props => props.theme.colors.text};
  width: 100%;

  @media ${props => props.theme.breaks.down('lg')} {
    font-size: 1.5rem;
  }

  @media ${props => props.theme.breaks.down('md')} {
    font-size: 1.3rem;
  }
`

export default ({text, author, id, className}) => {
 
 return (
 <Container id={id} className={className}>
   <Text>{text}</Text>
   <Author>{author}</Author>
 </Container>
 )
}