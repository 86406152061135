import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18.012"
      height="26.169"
      viewBox="0 0 18.012 26.169"
    >
      <path
        fill="#183264"
        d="M66.957 82.925l-1.415.814-.5-.861c-.04-.069-.109-.1-.148-.08l-1.759 1.017c-.047.025-.051.1 0 .17l.427.724a.145.145 0 01-.054.2l-1.98 1.14a.141.141 0 01-.192-.054l-1.571-2.715a.141.141 0 01.047-.192l5.455-3.124a.141.141 0 01.2.051l1.56 2.718a.141.141 0 01-.065.192z"
        data-name="Path 89"
        transform="translate(-50.856 -59.893)"
      ></path>
      <path
        fill="#183264"
        d="M53.074 47.112l-6.153-10.684a6.917 6.917 0 10-2.964 1.7l1.868 3.258-3.435 1.977a.141.141 0 00-.033.17l1.564 2.718a.138.138 0 00.192.051l3.435-1.973 2.982 5.19a.141.141 0 00.192.051l2-1.151a.97.97 0 00.351-1.307zM39.212 33.25a3.41 3.41 0 012.606-5.085 3.464 3.464 0 011.231.1 3.4 3.4 0 11-3.837 4.984z"
        data-name="Path 90"
        transform="translate(-35.185 -24.548)"
      ></path>
    </svg>
  );
}

export default Icon;