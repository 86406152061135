import React, {useEffect, useState, useRef} from 'react'
import styled from 'styled-components'

import RowWrapper from '../../utils/rowWrapper';

import Paint from '../../images/paint.png';

import ServiceTile from './service-tile';

import Popup from './popup';

import OrangeBar from './orange-bar';

import Key from '../../images/svgs/rent'
import Wrench from '../../images/svgs/wrench';
import Paper from '../../images/svgs/paperwork';
import Umbrella from "../../images/svgs/umbrella";
import Home from "../../images/svgs/house";
import Gavel from "../../images/svgs/gavel";
import Payments from "../../images/svgs/dashboard";
import Vacancy from "../../images/svgs/rental";




const Container = styled.div`
  min-height: 100vh;
  padding-top: 8rem;
  position: relative;
  background-color: ${props => props.theme.colors.gray};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  

  @media ${props => props.theme.breaks.down('md')} {
    margin-top: 5rem;
    min-height: auto;
  }
`

const MainFlex = styled.div`
  display: flex;
  justify-content: space-between;


@media ${props => props.theme.breaks.down('md')} {
  flex-direction: column;
}
`

const HeadingContainer = styled.div`
  width: 100%;
`

const Heading = styled.h2`

@media ${props => props.theme.breaks.down('md')} {
  text-align: center;
}
`

const SubHeading = styled.span`
  display: block;
  font-family: "Montserrat", sans-serif;
  font-size: 2.2rem;
  width: 30rem;
  margin-top: 1.5rem;
  line-height: 3rem;

  @media ${props => props.theme.breaks.down('md')} {
    font-size: 1.3rem;
    line-height: 1.8rem;
    text-align: center;
    margin: 3rem auto 5rem auto;
    width: 18rem;
  }
`

const ServiceFlex = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;

  @media ${props => props.theme.breaks.down('md')} {
    justify-content: center;
  }
`

const ImageFlex = styled.div`

/* margin-bottom: 4rem; */

  img {
    /* max-width: 45rem; */
    width: 50rem;
    position: relative;
    z-index: 1;
  

    @media ${props => props.theme.breaks.down('lg')} {
      max-width: 40rem;
      height: auto;
    }

    @media ${props => props.theme.breaks.down('l')} {
      max-width: 30rem;
      height: auto;
    }

  }

  @media ${props => props.theme.breaks.down('md')} {
    display: none;
  }
`

const TextFlex = styled.div`
  padding-bottom: 8rem;

  @media ${props => props.theme.breaks.down('md')} {
    padding-bottom: 0;
    margin-bottom: 8rem;
  }
`

const Tiles = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  max-width: 80rem;
  flex-wrap: wrap;

  @media ${props => props.theme.breaks.down('md')} {
    justify-content: center;
    max-width: none;
    width: 100%;
    height: max-content;
  }
`

const TapMobile = styled.span`
  display: block;
  font-size: 1.2rem;
  text-transform: uppercase;
  color: ${props => props.theme.colors.color};
  margin: 4rem auto;
  margin-top: 1.5rem;
  text-align: center;

  @media ${props => props.theme.breaks.up('md')} {
    display: none;
  }
`


 
export default ({id}) => {

  let openJs = false

  const [open, setOpen] = useState(false)

  const [info, setInfo] = useState({})

  const pop = useRef()

  const handleOpen = (e, id) => {


    if (id) {
      let title = tileInfo.filter(el => el.id === id)[0].altTitle
      let text = tileInfo.filter(el => el.id === id)[0].text
      setInfo({title, text})
    }

    setOpen(x => !x)
    openJs = !openJs

    console.log(open, openJs, "opensss");
  }

  const closePop = (e) => {

    console.log("doennnn", open, openJs);
    // if (open || openJs) {
      setOpen(false)
      openJs = false
    // }
  }


  useEffect(() => {
   
    document.addEventListener("mousedown", closePop);

    return () => {
        document.removeEventListener("mousedown", closePop);
    };
  }, [pop]);

  const tileInfo = [
    {
      id: "rent", 
      title: "Rent Collection",
      altTitle: "Keep the revenue streaming.",
      text: "Sending notices, collecting rent, sending rent increases, and, if necessary, filing evictions.",
      icon: <Key />
    },
    {
      id: "tenant", 
      title: "Tenant Follow-Up",
      altTitle: "A happy tenant is an easy tenant.",
      text: "Answering questions, fulfilling requests, paying attention, and making things easier for you.",
      icon: <Home />
    },
    {
      id: "paperwork", 
      title: "Paperwork Filing",
      altTitle: "No more deadline-phobia.",
      text: "Paying and filing mortgage, insurance, and taxes, with no late fees, delinquencies, or extra interest.",
      icon: <Paper />
    },
    {
      id: "legal", 
      title: "Legal Issues",
      altTitle: "You’re handled.",
      text: "Sending notices, collecting rent, sending rent increases, and, if necessary, filing evictions.",
      icon: <Gavel />
    },
    {
      id: "maintenance", 
      title: "Maintenance & Upkeep",
      altTitle: "Keeping things clean.",
      text: "Cleaning, cutting grass, removing snow, and making repairs. 24/7 emergency line for tenants.",
      icon: <Wrench />
    },
    {
      id: "billing", 
      title: "Billing & Payments",
      altTitle: "Signed, sealed, delivered.",
      text: "Paying all vendors on time and always making sure your account has positive cash flow.",
      icon: <Payments />
    },
    {
      id: "unit", 
      title: "Unit Turnover",
      altTitle: "In with the new.",
      text: "Move-ins and move-outs, paint jobs and renovations, and getting units ready for the next guys.",
      icon: <Umbrella />
    },
    {
      id: "vacancy", 
      title: "Vacancy Filling",
      altTitle: "No more empties.",
      text: "Maximizing the value of every unit by listing and filling them with the best tenants.",
      icon: <Vacancy />
    },
  ]
 
 return (
  <Container id={id}>
    <RowWrapper>

      <MainFlex>
      <ServiceFlex>
        <HeadingContainer>
          <Heading>
            Services
          </Heading>
          <SubHeading>
            Here are just some of the things we take care of:
          </SubHeading>
        </HeadingContainer>

     
        <ImageFlex>
          {/* <OrangeBar id="services-orange" height="29rem" left="14rem" top="10rem" /> */}
          <img src={Paint} alt="" />
        </ImageFlex>

        </ServiceFlex>


        <TextFlex>

          <TapMobile>Tap to expand</TapMobile>
          <Tiles>
            <Popup open={open} handleOpen={handleOpen} info={info} />
            {tileInfo.map(({id, title, altTitle, text, icon}) => 
              <ServiceTile pop={handleOpen} key={id} title={title} altTitle={altTitle} icon={icon} id={id} text={text} />
            )}
          
          </Tiles>
        </TextFlex>

      </MainFlex>
    </RowWrapper>
  </Container>
 )
}