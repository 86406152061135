import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17.993"
      height="21.338"
      viewBox="0 0 17.993 21.338"
    >
      <g transform="translate(-36.32 -34.16)">
        <path
          fill="#183264"
          d="M54.313 34.16v14.978l-5.934 5.976a1.271 1.271 0 01-.9.384H36.32V34.16z"
          data-name="Path 35"
        ></path>
        <path
          fill="#fff"
          d="M67.62 79.158V73.14h5.972z"
          data-name="Path 36"
          opacity="0.5"
          transform="translate(-19.279 -24.01)"
        ></path>
      </g>
    </svg>
  );
}

export default Icon;